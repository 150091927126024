import { produce } from "immer";
import { sortBy } from "../utils/iterable-utils";
import { createWithEqualityFn } from "zustand/traditional"
import { FileSlim, Metadata, ModifyInput, bulkDelete, bulkModify } from "../api/api";

type Presence = "waiting" | number | "available";

interface BlobState {
    url: string,
    clientPresence: Presence,
    serverPresence: Presence,
}

interface FilesSlice {
    files: Record<string, FileSlim>,
    blobs: Record<string, BlobState>,
    loadedYears: number[]
}

interface FileBlobs {
    main: string
    preview?: string
    thumbnail?: string
}

interface FileBlob {
    hash: string
    url: string
}

interface UploadData {
    name: string,
    blobs: {
        main: FileBlob
        preview?: FileBlob
        thumbnail?: FileBlob
    }
    meta: Metadata,
    event?: string,
}

const initialState: FilesSlice = {
    files: {},
    blobs: {},
    loadedYears: [],
};

export async function modifyFiles(mutations: ModifyInput[]) {
    useFiles.getState().modify(mutations);
    const appliedMutations = await bulkModify(mutations) as ModifyInput[];
    useFiles.getState().modify(appliedMutations);
}

export async function deleteFiles(files: string[]) {
    await bulkDelete(files);
    useFiles.getState().deleteFiles(files);
}

export async function checkBlobs(blobs: string[]) {
    const response = await fetch("/api/blob/check", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(blobs)
    });
    const result = await response.json();
    for (const [blob, isPresent] of Object.entries(result)) {
        if (isPresent) continue;
        useFiles.getState().setUploadProgress(blob, "waiting");
    }
    return result;
}

interface FilesStore extends FilesSlice {
    hydrateFiles(files: FileSlim[], year: number, clear?: boolean): void;
    uploadFile(data: UploadData): void;
    setUploadProgress(blob: string, progress: number | "waiting"): void;
    setUploaded(blob: string): void;
    modify(changes: ModifyInput[]): void;
    deleteFiles(files: string[]): void;
}

function sortFiles(files: Record<string, FileSlim>): Record<string, FileSlim> {
    const _files = sortBy(Object.entries(files), ([_, file]) => file.meta.date, true);
    return Object.fromEntries(_files);
}

export const useFiles = createWithEqualityFn<FilesStore>()((set, get) => ({
    ...initialState,
    hydrateFiles(files, year, clear = true) {
        const newBlobs = files
            .flatMap(({ blobs }) => [blobs.main, blobs.preview, blobs.thumbnail])
            .filter(x => !!x)
            .map(hash => ({
                hash: hash!,
                url: `/api/blob/${hash}`,
                clientPresence: "waiting",
                serverPresence: "available",
            } as const));

        const newFiles = clear ? {} : { ...get().files };
        Object.assign(newFiles, Object.fromEntries(files.map(file => [file.name, file])));

        set({
            files: newFiles,
            blobs: {
                ...Object.fromEntries(newBlobs.map(x => [x.hash, x])),
                ...get().blobs,
            },
            loadedYears: [...get().loadedYears, year],
        })
    },
    uploadFile({ name, blobs, meta, event }) {
        const replacedFile: FileSlim = {
            name,
            blobs: {
                main: blobs.main.hash,
                preview: blobs.preview?.hash ?? null,
                thumbnail: blobs.thumbnail?.hash ?? null,
            },
            meta,
            event: event ?? null,
            tags: [],
        }
        const newBlobs: Record<string, BlobState> = { ...get().blobs };
        for (const type in blobs) {
            const { hash, url } = blobs[type as keyof FileBlobs]!
            newBlobs[hash] = {
                url,
                clientPresence: "available",
                serverPresence: "waiting"
            };
        }

        const newFiles = {
            ...get().files,
            [replacedFile.name]: replacedFile
        }
        // reorder by date
        set({ files: sortFiles(newFiles), blobs: newBlobs })
    },
    setUploadProgress(blob, progress) {
        set(produce(state => {
            state.blobs[blob].serverPresence = progress;
        }))
    },
    setUploaded(blob) {
        set(produce(state => {
            state.blobs[blob].serverPresence = "available";
        }))
    },
    modify(changes) {
        const newFiles = { ...get().files };
        for (const { name, event, date, tags } of changes) {
            const file = newFiles[name] = { ...newFiles[name] };
            if (event !== undefined)
                file.event = event;
            if (date)
                file.meta = { ...file.meta, date };
            if (tags !== undefined)
                file.tags = tags;
        }
        set({ files: sortFiles(newFiles) });
    },
    deleteFiles(files) {
        set(produce(state => {
            for (const file of files) {
                delete state.files[file];
            }
        }))
    },
}), Object.is)

