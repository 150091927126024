import { useEffect } from "react";
import { FileSlim, getFilesByYear } from "./api/api";
import { useFiles } from "./files/filesStore";

export function FileHydrator() {
    useEffect(() => {
        (async function () {
            let allFiles: FileSlim[] = [];
            let year: number | null = new Date().getFullYear();
            while (year !== null) {
                const data = await getFilesByYear(year);
                useFiles.getState().hydrateFiles(data.files, year, false);
                year = data.prevYear;
            }
        })();
    }, []);

    return false;
}
