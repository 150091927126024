import { useFiles } from "./filesStore";
import { FilterStore, useFilter } from "../filter/filterStore";
import { memoize } from "lodash";
import { FileSlim } from "../api/api";

export interface FileGroup {
    title: string,
    uid: string
    files: FileSlim[]
}

function createUniqueKeyGenerator(): (key: string) => string {
    const takenUniqueKeys = new Map<string, number>();
    return (key: string) => {
        let uniqueKey = key;
        while (takenUniqueKeys.has(uniqueKey)) {
            const i = takenUniqueKeys.get(uniqueKey)!;
            takenUniqueKeys.set(uniqueKey, i + 1);
            uniqueKey += `-${i}`
        }
        takenUniqueKeys.set(uniqueKey, 1);
        return uniqueKey;
    }
}

function _getGroups(files: FileSlim[], filters: FilterStore) {
    if (filters.isActive) {
        files = files.filter((file) => filters.tags.every(f => file.tags?.includes(f))
            && (filters.event === undefined || filters.event === file.event));
    }

    const groups: FileGroup[] = []
    const groupsByKey = new Map<string, FileGroup>();

    const getUniqueKey = createUniqueKeyGenerator();

    const createGroup = (groupKey: string, file: FileSlim) => {
        const newGroup = {
            title: groupKey,
            uid: getUniqueKey(groupKey),
            files: [file]
        };
        groups.push(newGroup);
        groupsByKey.set(groupKey, newGroup);
    }

    for (const file of files) {
        const lastGroup = groups.at(-1);

        const groupKey = file.event ?? file.meta.date?.substring(0, 7);

        if (!lastGroup) {
            createGroup(groupKey, file);
        }
        else if (lastGroup.title !== groupKey) {
            const existingGroup = groupsByKey.get(groupKey);
            if (!existingGroup) {
                createGroup(groupKey, file);
            } else {
                const lastFileDate = existingGroup.files.at(-1)?.meta.date ?? "0000-00-00";
                const thisFileDate = file.meta.date;
                const dt = Math.abs(Date.parse(thisFileDate) - Date.parse(lastFileDate));
                if (dt > 1000 * 60 * 60 * 24 * 30) // one month
                    createGroup(groupKey, file);
                else
                    existingGroup.files.push(file);
            }
        }
        else {
            lastGroup.files.push(file);
        }
    }
    return groups;
}

export const getGroups = memoize(_getGroups);

export function useGroups() {
    const _files = useFiles(state => state.files);
    const _filters = useFilter();
    return getGroups(Object.values(_files), _filters);
}
