import React, { useState } from "react";
import { useGroups } from "../files/useGroups";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { IconButton } from "../utils/IconButton";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { groupBy } from "../utils/iterable-utils";

export function Navigation() {
    const [showModal, setShowModal] = useState(false);

    return <div className="navigation">
        <div className="mobile">
            <IconButton
                icon={faList}
                text="Go to"
                onClick={() => setShowModal(true)}
            />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <ModalHeader>
                    <ModalTitle>Navigate to</ModalTitle>
                </ModalHeader>
                <ModalBody onClick={() => setShowModal(false)}>
                    {showModal && <NavList />}
                </ModalBody>
            </Modal>
        </div>
        <div className="desktop">
            <NavList />
        </div>
    </div>
}

function NavList() {
    const groups = useGroups();
    const groupsByYear = groupBy(groups, g => g.files.at(-1)?.meta.date.substring(0, 4));

    return <div>
        {groupsByYear.map(year => <div key={year.key}>
            <h3>{year.key}</h3>
            <ul>{year.items.map(x => <li key={x.uid}>
                <a href={`#${x.uid}`}>
                    {x.title}
                </a>
            </li>)}</ul>
        </div>)}
    </div>

    return <ul>
        {groups.map(x => <li key={x.uid}>
            <a href={`#${x.uid}`}>
                {x.title}
            </a>
        </li>)}
    </ul>
}
