import React, { useEffect, useState } from "react";
import "./App.scss"
import PreviewScreen from "./preview/Preview";
import { DropZone } from "./drop/DropZone";
import { Files } from "./files/Files";
import { useFiles } from "./files/filesStore";
import _ from "lodash";
import { Toolbar } from "./tools/Toolbar";
import { UploadProgressArea } from "./upload/UploadProgressArea";
import * as db from "./upload/localDb"
import { processAndUpload } from "./upload/processAndUpload";
import { SessionRefresher } from "./auth/SessionRefresher";
import { FileHydrator } from "./FileHydrator";

export default function App() {
    const isLoaded = useFiles(f => f.loadedYears.length > 0);

    // On first load, re-add everything from the database to the queue.
    useEffect(() => {
        db.getFiles().then(async files => {
            for (const { path, event } of files) {
                if (!electronMain) return;
                const { buffer, lastModified } = await electronMain.loadFile(path);
                // TODO: Directly loading the full blob from disk might not be 
                //       ideal, since it could exhaust RAM. Also, it causes the
                //       initialization to be really slow.
                processAndUpload({
                    blob: new Blob([buffer]),
                    lastModified,
                    name: path.split(/[/\\]/).at(-1)!,
                    event,
                    path,
                })
            }
        })
    })

    return <>
        {electronMain && <pre style={{ float: "right" }}>
            Chrome {electronMain.chromeVersion} <br />
            Node {electronMain.nodeVersion} <br />
            Electron {electronMain.electronVersion} <br />
        </pre>}
        {isLoaded ? <>
            <DropZone>
                <div className="main-content">
                    <Files />
                    <Toolbar />
                </div>
            </DropZone>

            <UploadProgressArea />

            <PreviewScreen />

            <SessionRefresher />

        </> : "Loading..."}

        <FileHydrator />
    </>
}
