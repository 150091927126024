export function registerZoomEventHandlers(imgStack: HTMLDivElement, onZoomCallback: () => void) {
    const onMouseMove = (e: MouseEvent) => {
        if (e.buttons === 0) return;
        applyDrag(imgStack, e.movementX, e.movementY);
        e.preventDefault();
        e.stopPropagation();
    }

    const onScroll = (e: WheelEvent) => {
        if (e.ctrlKey) return;
        onZoomCallback();
        const zoomChange = 2 ** (Math.sign(e.deltaY) * -0.5);
        applyZoomAroundPoint(imgStack, e.clientX, e.clientY, zoomChange);
        e.preventDefault();
        e.stopPropagation();
    };

    let lastTap: { x: number, y: number, ts: number } | null = null;

    const onTouchStart = (e: TouchEvent) => {
        e.preventDefault();
        if (e.touches.length === 1) {
            const touch = e.touches.item(0)!;
            const tap = { ts: performance.now(), x: touch.clientX, y: touch.clientY }

            const hasZoom = getZoomProps(imgStack).zoom !== 1;

            if (lastTap) {
                const pixelDistance = ((tap.x - lastTap.x) ** 2 + (tap.y - lastTap.y) ** 2) ** 0.5;
                const dt = tap.ts - lastTap.ts;
                const isDoubleTap = dt > 100 && dt < 500 && pixelDistance < 30;
                if (isDoubleTap) {

                    // briefly enable transition: transform to make it a bit smoother
                    imgStack.style.transition = "transform 0.2s ease";
                    setTimeout(() => {
                        imgStack.style.transition = ""
                    }, 300)

                    if (hasZoom)
                        resetZoom(imgStack);
                    else
                        applyZoomAroundPoint(imgStack, tap.x, tap.y, 10);


                    lastTap = null;
                    e.stopPropagation();
                    return;
                }
            }
            if (hasZoom) {
                e.stopPropagation();
                handleTouchPan(e);
            }
            lastTap = tap;
        }
        else if (e.touches.length === 2) {
            handlePinchZoom(e);
            onZoomCallback();
            e.stopPropagation();
        }
    }

    imgStack.addEventListener("mousemove", onMouseMove);
    imgStack.addEventListener("wheel", onScroll, { passive: false });
    imgStack.addEventListener("touchstart", onTouchStart, { passive: false });
    return () => {
        imgStack.removeEventListener("mousemove", onMouseMove);
        imgStack.removeEventListener("wheel", onScroll);
        imgStack.removeEventListener("touchstart", onTouchStart);
    }
}

function handlePinchZoom(e: TouchEvent) {
    const el = e.currentTarget as HTMLDivElement;
    const imgStack = (e.target as HTMLElement).closest(".img-stack") as HTMLDivElement;
    if (!imgStack) return;

    let lastCenter = getCenter(e.touches);
    let lastDistance = getDistance(e.touches);

    el.addEventListener("touchend", tidyUp);
    el.addEventListener("touchmove", onMove);

    function onMove(e: TouchEvent) {
        const currentDistance = getDistance(e.touches);
        let zoomChange = currentDistance / lastDistance;

        const currentCenter = getCenter(e.touches);
        applyZoomAroundPoint(imgStack, currentCenter[0], currentCenter[1], zoomChange);

        const offset = [currentCenter[0] - lastCenter[0], currentCenter[1] - lastCenter[1]];
        applyDrag(imgStack, offset[0], offset[1]);

        lastDistance = currentDistance;
        lastCenter = currentCenter;
    }

    function tidyUp() {
        el.removeEventListener("touchend", tidyUp);
        el.removeEventListener("touchmove", onMove);
    }
}

function handleTouchPan(e: TouchEvent) {
    const el = e.currentTarget as HTMLDivElement;
    e.stopPropagation();

    let last = e.touches.item(0)!;

    const onTouchMove = (e: TouchEvent) => {
        e.preventDefault();

        const current = e.touches.item(0)!;
        const dX = current.clientX - last.clientX;
        const dY = current.clientY - last.clientY;
        last = current;
        applyDrag(el, dX, dY);
    }

    const endTouch = () => {
        el.removeEventListener("touchstart", endTouch);
        el.removeEventListener("touchend", endTouch);
        el.removeEventListener("touchmove", onTouchMove);
    }

    el.addEventListener("touchstart", endTouch, { passive: false });
    el.addEventListener("touchend", endTouch, { passive: false });
    el.addEventListener("touchmove", onTouchMove, { passive: false });
}

function resetZoom(imgStack: HTMLDivElement) {
    imgStack.style.transform = "";
    setZoomProps(imgStack, { offsetX: 0, offsetY: 0, zoom: 1 });
}


function getDistance(touches: TouchList) {
    const x0 = touches[0].clientX;
    const y0 = touches[0].clientY;
    const x1 = touches[1].clientX;
    const y1 = touches[1].clientY;
    return ((x1 - x0) ** 2 + (y1 - y0) ** 2) ** 0.5;
}

function getCenter(touches: TouchList) {
    const x0 = touches[0].clientX;
    const y0 = touches[0].clientY;
    const x1 = touches[1].clientX;
    const y1 = touches[1].clientY;
    return [(x0 + x1) / 2, (y0 + y1) / 2];
}

function clamp(value: number, min: number, max: number): number {
    value = Math.min(value, max);
    value = Math.max(value, min);
    return value;
}

interface ZoomProps {
    zoom: number
    offsetX: number
    offsetY: number
}

function getZoomProps(el: HTMLDivElement): ZoomProps {
    return {
        zoom: parseFloat(el.dataset["zoom"] ?? "1"),
        offsetX: parseFloat(el.dataset["offsetX"] ?? "0"),
        offsetY: parseFloat(el.dataset["offsetY"] ?? "0")
    }
}
function setZoomProps(el: HTMLDivElement, props: Partial<ZoomProps>) {
    if (props.zoom) {
        if (props.zoom === 1)
            delete el.dataset["zoom"];
        else
            el.dataset["zoom"] = props.zoom.toString();
    }

    if (props.offsetX !== undefined)
        el.dataset["offsetX"] = props.offsetX.toString();
    if (props.offsetY !== undefined)
        el.dataset["offsetY"] = props.offsetY.toString();
}

export function applyZoomAroundPoint(imgStack: HTMLDivElement,
    clientX: number, clientY: number,
    zoomChange: number
) {
    const initialZoom = getZoomProps(imgStack).zoom;
    const newZoom = clamp(initialZoom * zoomChange, 1, 10);
    if (newZoom === initialZoom) return;

    const rect = imgStack.getBoundingClientRect();
    const centerX = (rect.left + rect.right) / 2;
    const centerY = (rect.top + rect.bottom) / 2;

    let dX = (clientX - centerX) * (1 - newZoom / initialZoom);
    let dY = (clientY - centerY) * (1 - newZoom / initialZoom);

    applyTranslation(imgStack, dX, dY, newZoom);
}

function applyDrag(imgStack: HTMLDivElement, dx: number, dy: number) {
    applyTranslation(imgStack, dx, dy);
}

function applyTranslation(imgStack: HTMLDivElement, dx: number, dy: number, newZoom?: number) {

    let { offsetX, offsetY, zoom } = getZoomProps(imgStack);
    let { height, width } = imgStack.getBoundingClientRect();
    if (newZoom !== undefined) {
        width *= newZoom / zoom;
        height *= newZoom / zoom;
        zoom = newZoom;
    }

    offsetX += dx;
    offsetY += dy;

    const { height: natHeight, width: natWidth } = imgStack.closest(".img-stack-container")!.getBoundingClientRect();

    // TODO: limit the "draggable area" to the "initial area", i.e. the
    // area that the image initially covered
    let maxDy = height / 2;
    let maxDx = width / 2;

    if (zoom === 1) {
        maxDy -= natHeight / 2;
        maxDx -= natWidth / 2;
        maxDy = Math.max(0, maxDy);
        maxDx = Math.max(0, maxDx);
    }
    else {
        maxDy = Math.abs(maxDy);
        maxDx = Math.abs(maxDx);
    }

    offsetY = clamp(offsetY, -maxDy, maxDy);
    offsetX = clamp(offsetX, -maxDx, maxDx);

    setZoomProps(imgStack, { offsetX, offsetY, zoom });

    imgStack.style.transform = ""
        + `translate(${offsetX}px, ${offsetY}px) `
        + `scale(${zoom}) `
}
