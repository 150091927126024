import React from "react";
import { faFilter, faFilterCircleXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { TagsControl, TagsFormikControl } from "../utils/TagsControl";
import { useFiles } from "../files/filesStore";
import { Formik } from "formik";
import { Accordion, Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFilter } from "./filterStore";


export function FilterUi() {
    const { isActive, setActive, event, tags, setEvent, setTags } = useFilter();

    const { allTags, allEvents } = useFiles(({ files }) => {
        if (!isActive) return { allTags: [], allEvents: [] }
        const allFiles = Object.values(files);
        const allTags = [...new Set(allFiles.flatMap(x => x.tags))].filter(x => x !== undefined).sort();
        const allEvents = [...new Set(allFiles.flatMap(x => x.event))].sort();
        return { allTags, allEvents };
    });

    return <Accordion activeKey={isActive ? "1" : null} onSelect={e => setActive(!!e)}>
        <Accordion.Item eventKey="1">
            <Accordion.Header>
                <FontAwesomeIcon icon={isActive ? faFilterCircleXmark : faFilter} />
                &nbsp;Filter
            </Accordion.Header>
            <Accordion.Body style={{ display: "grid", gridTemplateColumns: "auto 1fr", rowGap: "10px", columnGap: "5px" }}>
                <span>Tags:</span>
                <div>
                    <TagsControl className="badge bg-primary" tagChoices={allTags} chosenTags={tags} setChosenTags={setTags} />
                </div>
                <span>Event:</span>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary">
                        {event === undefined ? "No event selected" :
                            event === null ? <i>Without event</i> :
                                event}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {allEvents.map(e => <DropdownItem key={e} onClick={() => setEvent(e)}>
                            {e ?? <i>Without event</i>}
                        </DropdownItem>)}
                        {event !== undefined &&
                            <DropdownItem style={{ color: "var(--bs-gray)" }} onClick={() => setEvent(undefined)}>
                                <FontAwesomeIcon icon={faXmarkCircle} />&nbsp;
                                Remove filter
                            </DropdownItem>}
                    </Dropdown.Menu>
                </Dropdown>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>;
}
