export function groupBy<TKey, T>(iterable: Iterable<T>, key: (item: T) => TKey) {
    const map = new Map<TKey, T[]>();
    for (const item of iterable) {
        const k = key(item);
        const list = map.get(k);
        if (list === undefined)
            map.set(k, [item]);
        else
            list.push(item)
    }
    return [...map.keys()].map(key => ({ key, items: map.get(key)! }));
}

export function sortBy<TKey extends string | number, T>(iterable: Iterable<T>, key: (item: T) => TKey, descending = false) {
    return [...iterable].sort((a, b) => {
        const ka = key(a), kb = key(b);
        if (ka > kb) return descending ? -1 : 1;
        if (ka < kb) return descending ? 1 : -1;
        return 0;
    })
}